.swiper-card {
  position: relative;
  width: 283px;
  height: 321px;
  overflow: hidden;
  touch-action: pan-y;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  margin: 30px 0 24px 0;

  &--grabbing {
    cursor: grabbing;
  }
  
  &:not(&--grabbing) {
    cursor: grab;
  }
  
  &__cards-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  &__card {
    position: absolute;
    width: 257px;
    height: 321px;
    left: calc(50% - 141.5px); // Center horizontally
    overflow: hidden;
  }
  
  // Media containers
  &__video-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  &__image-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f0efed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // Image styling
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
    display: block;
  }
  
  // Video controls
  &__video-control-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    background: transparent;
  }
  
  &__play-button,
  &__pause-button {
    width: 25px;
    height: 25px;
    // border-radius: 50%;
    // background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease;
    position: absolute;
    left: 6px;
    bottom: 6px;
  }
  
  &__play-button-icon {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid rgb(97, 89, 89);
    margin-left: 2px;
  }
  
  &__pause-button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:before,
    &:after {
      content: "";
      width: 3px;
      height: 12px;
      background-color: rgb(97, 89, 89);
      margin: 0 2px;
      border-radius: 1px;
    }
  }
  
  // Overlay for stacked cards
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    pointer-events: none;
  }
  
  // Empty state
  &__empty-state {
    text-align: center;
    padding-top: 40%;
  }
}
