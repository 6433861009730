.cat-loader{
  width: 8px;
  height: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: loader 1s infinite linear alternate;
}
.lookcard-gif{
  width:30px;
  height:30px;

}
 
@keyframes loader {
  0% {
    box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
    background:#6D6F73;
  }
  33% {
    box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
    background: #0002;
  }
  100% {
    box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
    background: #6D6F73;
  }
}
 
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
 
 
.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  white-space: nowrap;
  height: auto;
  overflow-x: auto;
  padding: 5px;
  margin: 0px 0px 0px 0px;
  scroll-snap-type: x mandatory;
  // margin-top: -20%;
}
 
 
.mcat-text{
  font-size: 16px;
  line-height: 30px;
  font-family: foundersGroteskTextRegular;
  font-style: normal;
  font-weight: 400;
  color: #041E3A;
  padding-bottom: 16px;
  margin-bottom: 40px;
}
 
.cards::-webkit-scrollbar {
  display: none !important;
}
 
.box {
  position: relative;
  max-width: 300px;
  width: 100% !important;
  height: 430px;
  flex: 0 0 320px;
  background: #FFFFFF;
  border-radius: 10px;
  border: none;
  transition: transform 0.3s;
  scroll-snap-align:start;
}
 
.box:hover {
  transform: scale(1.03);
  transform-origin: center;
  cursor: pointer;
}
 
.noHover{
  pointer-events: none;
}
 
.effectHover{
  pointer-events: all;
}
 
.textLook {
  position: absolute;
  display: block;
  font-weight: 400;
  font-style: italic;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 1px;
  color: #6D6F73;
  margin-top: 5px;
  font-family: leJeuneDeck;
  overflow: hidden;
}
 
.content-title_container {
  position: absolute;
  margin-top: 30px;
  // height: 60px;
  // top: 44px;
  // left: 24px;
}
 
.content-title {
  font-family: leJeuneDeck;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 28px;
  line-height: 34px;
  color: #041e3a;
  // max-width: 13ch;
  word-wrap: break-word;
  text-align: center;
}
 
 
.content-title::after {
  content: attr(data-first-word);
  white-space: pre-line;
  display: inline-block;
}
 
.bottomTextContainer {
  position: absolute;
  top: 393px;
  left: 22.5px;
  border: none;
  background: none;
  width: 60% !important;
}
 
.viewText {
  font-family: foundersGroteskTextMedium;
  position: absolute;
  display: block;
  font-size: 11px;
  line-height: 15px;
  color: #041e3a;  
  opacity: 0;
  text-wrap: nowrap;
  top: 0;
}
 
.box:hover .viewText {
  opacity: 1;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
 
.coat1 {
  position: absolute;
  height: 272px !important;
  top: 83px;
  left: 57px;
  z-index: 2;
}
 
.shirt1 {
  height: 202px !important;
  position: absolute;
  top: 118px;
  z-index: 1;
  // left: 5px;
}
 
.pant1 {
  position: absolute;
  top: 118px;
  left: 156px;
  z-index: 1;
  height: 202px !important;
}
 
.watch1 {
  position: absolute;
  height: 188px !important;
  top: 187px;
  left: 154px;
  z-index: 3;
}
 
.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}
 
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
.multicat_template{
  margin-bottom: 40px;
  .multicat-header{
    display: grid;
    grid-template-rows: 140px;
  }
  .multi-res{
    margin-bottom: 16px !important;
  }
  p {
    margin-bottom: 0px !important;
  }
}
 
//********************************//********************************
      //************   Media Query     *************//
//********************************//********************************
 
@media screen and (max-width: 959px) {
  .cat-loader{
    top: 48px;
  }
  .mcat-text{
    font-size: 13px;
    line-height: 22px;
  }  
  .viewText {
    font-size: 11px;
    line-height: 15px;
    opacity: 1;
  }
  .multicat_template{
    .multi-res{
      margin-bottom: 24px !important;
    }
    p{
      margin-bottom: 0px !important;
    }
  }
}

@media screen and ((min-width: 320px) and (max-width: 374px)) {
  .cards {
    display: flex;
    margin-left: -7px;
    margin-right: -16px;
    // scroll-padding-left: 8px;
    scroll-padding-left: 0px;
    scroll-padding-right: 16px;
    // margin-top: -80%;
  }
}
 
@media screen and ((min-width:375px) and (max-width: 576px)) {
  .cards {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  // margin-top: -80%;
  }
}
 
@media screen and ((min-width: 577px) and (max-width:992px)) {
  .cards {
  display: flex;
  margin-left: -4px;
  margin-right: 0px;
  gap: 16.88px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  overflow: visible;
  }
}
 
@media screen and (min-width: 992px) {
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Ensures three columns */
    gap: 18px;
    margin-left: -4px;
    margin-right: 0px;
    overflow-x: hidden;
    // margin-top: 6px;
    margin-bottom: 30px;
    overflow: visible;
  }
}
 
@media screen and (min-width: 1200px)  {
  .cards {
    display: flex;
    grid-template-columns: repeat(3, 1fr); /* Ensures three columns */
    gap: 18px;
    margin-bottom: 30px;
  }
  .watch1 {
    left: 170px;
  }
}
 
@media screen and ((min-width: 992px) and (max-width:1200px)) {
  .watch1 {
    left: 155px;
  }
}
 
 
@media screen and (max-width: 576px) {
  .mcat-box-ms{
    margin-left: 32px;
  }
  // .mcat-box-me{
    // margin-right: 16px !important;
  // }
  .box {
    width: 100%;
    height: 395px;
    border-radius: 10px;
    transform: none;
    transition: none;
    flex: 0 0 300px;
  }
  .mcard-position{
    position: relative;
    right: 16px;
  }
  .box:hover {
    transform: none;
  }
  // .textLook {
    // font-size: 8.44px;
    // line-height: 9.11px;
    // letter-spacing: 1.07px;
  // }
  .content-title_container {
    // height: 57px;
    text-wrap: auto;
    // text-align: left;
  }
  // .content-title {
    // font-size: 26.25px;
    // line-height: 31.88px;
  // }
  .bottomTextContainer {
    height: 15px;
    top: 365px;
    left: 20px;
  }
 
}
 
// @media screen and (max-width: 375px) {
// .textLook {
//   font-size: 11px;
//   line-height: 12px;
//   letter-spacing: 1px;
// }
// }
 
 
@media screen and (min-width: 577px) and (max-width: 767px){
  .mcat-box-me{
    margin-right: -5px;
  }
}
@media screen and (min-width:768px)and (max-width:959px){
  .mcat-box-me{
  margin-right: -1px;
  }
}
@media screen and ((min-width: 375px) and (max-width: 419px)) {
  .cards{
    scroll-padding-left: 10% !important;
  }
}
@media screen and (min-width: 420px) and (max-width: 440px){
  .cards{
    scroll-padding-left: 10% !important;
  }
  .bottomTextContainer {
    top: 93% !important;
  }
  .box{
    max-width: 80% !important;
    height: 456px !important;
  }
  .coat1{
    width: 64.5% !important;
    height: 68.9% !important;
    left: 19% !important;
  }
  .shirt1{
    width: 48% !important;
    height: 51.2% !important;
  }
  .pant1{
    width: 48% !important;
    height: 51.2% !important;
    left: 52% !important;
  }
  .watch1 {
    width: 44.8% !important;
    height: 47.6% !important;
    left: 51.2% !important;
    top: 44.3% !important;
  }
}

.outfit-loader-video { 
  video{
    border-radius: 20px !important;
  }
}

.animation-coat{
  animation: coat 3s forwards;
  animation-delay: 1s;
  opacity: 0;
  @keyframes coat {
    0% {
      opacity: 0;
      scale : 60%;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 1;
      scale: 100%;
    }
  }
}
.animation-shirt{
  animation: shirt 3s forwards;
  animation-delay: 1s;
  opacity: 0;
  @keyframes shirt {
    0% {
      opacity: 0;
      scale : 60%;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 1;
      scale: 100%;
    }
  }
}
.animation-pant{
  animation: pant 3s forwards;
  animation-delay: 1s;
  opacity: 0;
  @keyframes pant {
    0% {
      opacity: 0;
      scale : 60%;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 1;
      scale: 100%;
    }
  }
}
.invisible {
  opacity: 0;
}
.visible {
  opacity: 1;
  transition: opacity 0.3s ease;
}  
.single-look-container, .product-listing-container {
  transition: height 0.3s ease;
  position: relative;
}

.loading-placeholder {
  background-color: transparent;
  transition: opacity 0.3s ease;
}