.polo-product-img {
  margin-bottom: 40px;
  margin-top: 10px;
}
.polo_img {
  margin: -50px 0;
}
.polo-img4,
.polo-img5 {
  margin: 10px 0px 40px 0px;
}
.polo-txt {
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #6d6f73;
}
.footer-txt {
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #6d6f73;
  margin: 10px 0 40px 0;
}
