.prompts-carousel {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }

  &--mobile {
    margin-left: calc(50% - 135px);
  }

  &__header {
    text-align: left;
    padding: 0px 0;
    width: 270px;
    margin-top: 0;
  }

  &__subheading {
    font-weight: bold;
    font-size: 9px;
    margin: 0 0 12px 0;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #c6c8cc;
    font-family: foundersGroteskTextRegular;
    line-height: 18px;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0 2px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    width: auto;
  }

  &__title {
    flex: 1 1;
    margin: 0 0 7px 0;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    font-family: leJeuneDeck;
    line-height: 18px;
    font-style: italic;
    align-self: center;
    margin-top: -6%;
  }
  // .page{
  //   margin-top: 23%;
  // }

  &__pagination {
    font-size: 9px;
    line-height: 18px;
    letter-spacing: 2px;
    font-weight: 400;
    color: #fff;
    font-family: foundersGroteskTextRegular;
    margin: 0;
    //margin-bottom: 14px;
    flex-shrink: 0;
    align-self: center;
    margin-top: -10%;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.01)
      );
    border: none;
    cursor: pointer;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    padding: 8px;

    &--next {
      right: 25px;
    }

    &--prev {
      left: 25px;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  &__arrow-icon {
    padding-top: 4px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__nav-button {
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.01)
      );
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    padding: 8px;

    &--prev {
      margin-right: 15px;
    }

    &--next {
      margin-left: 15px;
    }

    &--disabled {
      opacity: 0.5;
      cursor: default;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__nav-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__slides {
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 10px 0;
    width: calc(261px * 3 + 10px * 2);
    min-width: calc(261px * 3 + 10px * 2);
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &--mobile {
      overflow-x: auto;
      width: 100%;
      min-width: 100%;
    }
  }

  &__slide {
    flex: 0 0 276px;
    width: 261px;
    margin-right: 10px;
    box-sizing: border-box;
  }

  &__card {
    display: flex;
    border-radius: 13px;
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 132px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.01)
      );
    gap: 15px;
  }

  &__image-container {
    flex: 0 0 auto;
  }

  &__image {
    width: 99px;
    height: 99px;
    border-radius: 12px;
    object-fit: cover;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  &__card-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 8px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-family: leJeuneDeck;
  }

  &__card-description {
    font-size: 11px;
    color: #ffffff;
    display: block;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.4;
    letter-spacing: 0px;
    font-family: foundersGroteskTextRegular;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
