.offcanvas-body {
    padding: 0 !important; /* Remove padding */
  }
 
  .offcanvas{
    width:100% !important;
  }
  .non-swipable {
    touch-action: none; /* Prevent touch actions */
    pointer-events: none; /* Disable pointer events */
  }
 
 .modalContainer{
     overflow: hidden;
  .offcanvas.offcanvas-bottom {
    height: 100vh;
  }
 
  @media screen and (max-width: 767px){
    .offcanvas.offcanvas-bottom {
      height: 100vh;
      width: 100%;
      background-clip: border-box !important;
      transform: translateY(100%) !important;
    }
   
    .offcanvas.show:not(.hiding), .offcanvas.showing {
      transform: none !important;
    }
  }
 
  .historyBtn{
    background-color: transparent !important;
    color:white;
    border: 1px solid white !important;
    border-radius: 32px;    
    // backdrop-filter: blur(44px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding:6px 20px;  
    text-transform: uppercase;
    position: absolute;
    left: 37%;
    top: 85%;
    // bottom: 25px;
  }
 
 
  .histIcon{
    height:12px;
    width:12px;
    margin-right: 8px;
  }
 
  .histryFont{
    font-size: 10px;
    line-height: 10px;
    font-family: foundersGroteskTextMedium;
  }
  // .offcanvas-bottom {
  //   height: 100vh !important;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 1050;
  //   background-color: white; /* Adjust as needed */
  // }
 
 
 
//   @media screen and (max-width: 767px) {
//     .offcanvas.offcanvas-bottom {
//         // height: 100vh;
//         // width: 100%;
//         // background-clip: border-box !important;
//          transform: translateY(0) !important;
//     }
// }
 
 }
 
  .content-container{
    padding:20px 18px 25px 18px;
 
    .ms-2 {
      margin-left: 0 !important;
  }
 
    .dJhYRn {
      padding: 10px !important;
    }
 
    .iconStyle {
      height: 20.82px;
      width: 14px;
    }
 
    .imgStyle{
      height: 10.82px;
      width: 12.73px;
    }
 
    .textTruncate {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
 
    .qStyle {
      font-family: foundersGroteskTextRegular;
      font-size: 13px;
      color: #041E3A;
      line-height: 25px;
    }
 
    .dayDateStyle {
      font-family: foundersGroteskTextRegular;
      font-size: 8px;
      line-height: 24px;
      letter-spacing: 2px;
      color: #041E3A;
      text-transform: uppercase;
    }
 
 
  }
 
  .btnparent{
    gap:8px;
  .button-label{
    font-size: 13px;
    font-family: foundersGroteskTextRegular;
    line-height: 17px;
   
  }
 
  .btn {
   --bs-btn-padding-x: 0 !important;
  }
 
 }
 
.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm .offcanvas-xxl{
--bs-offcanvas-padding-y: 0rem !important;
}
@media screen and (max-width: 576px) and (max-height: 780px) {
  .historyBtn{
    position:fixed !important;    
    top: 37.5rem !important;
  }
}
 