.rl-text {
  font-family: leJeuneDeck;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: #996e40;
  margin-bottom: 20px;
}
.text-head {
  font-family: foundersGroteskTextMedium;
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  color: #996e40;
  margin-top: 20px;
}
.text-description {
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #996e40;
  margin-bottom: 30px;
}
.description {
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #041e3a;
  p {
    margin-bottom: 20px !important;
  }
}
.copy-txt {
  padding: 125px 20px 125px 20px;
  margin: -50px 0;
  background: #041e3a;
  font-family: leJeuneDeck;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #FFFFFF;
}
.sign{
  margin-top: 30px;
}
