.art-of-bundling-up {
  display: grid;
  grid-template-rows: 300px;
  height: 100vh;
  .content {
    padding: 40px 30px 0px 30px;
    border-radius: 20px;
    background-color: #ffffff;
    border: none;
    margin: 0 16px 30px 16px;
    box-shadow: 0px 5px 10px 0px #0000000d;
  }

  .letter {
    animation: changeColor 0.02s ease forwards;
  }

  @keyframes changeColor {
    0% {
      color: #ffffff;
    }
    100% {
      color: #041e3a;
    }
  }

  .title {
    color: #ffffff;
    font-family: leJeuneDeck;
    font-weight: 400;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 13px;
    gap: 3px;
  }

  .subheader {
    // font-family: Le Jeune Text;
    font-family: leJeuneDeck;
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 30px;
    color: #ffffff;
    gap: 3px;
  }

  .n-text {
    // font-family: Le Jeune Text;
    font-family: leJeuneDeck;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    line-height: 14.4px;
    letter-spacing: 1px;
    text-align: center;
    color: #996e40;
    padding-bottom: 8px;
    border-bottom: 1px solid #996e40;
    width: 47px;
    margin-bottom: 20px;
  }
  .product-header {
    font-family: leJeuneDeck;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #041e3a;
  }
  .product-copy {
    font-family: foundersGroteskTextRegular;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    margin: 25px 0px 20px 0px;
    color: #041e3a;
  }
  .product-grid {
    margin-bottom: 40px;
  }
  .row-gap {
    row-gap: 2px;
  }
  .pe {
    padding-right: 1px;
  }
}
.product-image {
  cursor: pointer;
}
.static-footer {
  text-align: center;
  align-items: center;
  .footer-head {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
    font-family: leJeuneDeck;
    line-height: 28px;
    letter-spacing: 0px;
    color: #041e3a;
  }
  .static-chat-btn {
    margin: 0px;
    padding: 9px 10px;
    background-color: #041e3a;
    border: none;
    &:hover {
      background-color: #041e3a;
    }
  }
  .static-label {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 8px;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 1px;
    font-family: foundersGroteskTextMedium;
    color: #041e3a;
  }
}
.overflow{
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

/*** Media Queries ****/
@media screen and (max-width: 576px) {
  .top-img {
    width: 100%;
  }
}

.carousel-polar-div {
  animation: polarfadeIn 3s ease-in-out;
  animation-delay: 0.03s;
}
@keyframes polarfadeIn {
from {
  opacity: 0;
}
to {
  opacity: 1;
}
}